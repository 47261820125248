import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/home',
        component: () => import('../views/index'),
        children: [
            {path: '/home', component: () => import('../views/Home')},
            {path: '/product', component: () => import('../views/productDescription')},
            {path: '/about', component: () => import('../views/aboutUs')}
        ]
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
